import { sprintf } from 'sprintf-js';

import { apiFetch } from './apiFetch';
import availableApi from './config';

import { defaultLanguage } from '../../constants/languages';
import { defaultSeason } from '../../constants/seasons';

function getApiItemData({ itemType, itemId, language = defaultLanguage, season = defaultSeason }) {
    const apiUrl = sprintf(availableApi[itemType].apiItemUrlPattern, itemId);
    const apiRequestParams = {
        method: 'GET',
        headers: {
            'X-Estm-Site-Season': season,
            'X-Estm-Site-Language': language,
        },
    };

    return apiFetch(apiUrl, apiRequestParams)
        .then(response => response.json())
        .catch(() => {
            // eslint-disable-next-line no-console
            console.error(`Fail to fetch API request: ${apiUrl}`);
        });
}

export {
    getApiItemData,
};
