// eslint-disable-next-line import/prefer-default-export
export const ESTM_LANGUAGE_DE = 'de';
export const ESTM_LANGUAGE_EN = 'en';
export const defaultLanguage = ESTM_LANGUAGE_EN;
export const languageSwitcherTitles = [
    { language: ESTM_LANGUAGE_DE, value: 'Deutsch' },
    { language: ESTM_LANGUAGE_EN, value: 'English' },
];

export const availableLanguages = [ESTM_LANGUAGE_EN, ESTM_LANGUAGE_DE];

export const translations = {
    contact: [{ language: ESTM_LANGUAGE_DE, value: 'Kontakt' }, { language: ESTM_LANGUAGE_EN, value: 'Contact' }],
};
