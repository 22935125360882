import React from 'react';

function TargetSelect(props) {
    return (<select {...props}>
        <option value={'_self'}>_self</option>
        <option value={'_blank'}>_blank</option>
    </select>);
}

export default TargetSelect;
