import React from 'react';
import PropTypes from 'prop-types';

import { getApiItemData } from './api';
import availableApi from './config';
import { defaultSeason } from '../../constants/seasons';
import { defaultLanguage } from '../../constants/languages';

class ItemDataFetcher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            isLoading: false,
            error: null,
        };
        this.processItemRequest = this.processItemRequest.bind(this);
    }

    componentDidMount() {
        this.processItemRequest();
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemType !== prevProps.itemType
            || this.props.itemId !== prevProps.itemId
            || this.props.language !== prevProps.language
            || this.props.season !== prevProps.season
        ) {
            this.processItemRequest();
        }
    }

    processItemRequest() {
        this.setState({ isLoading: true });
        const { itemType, itemId, language, season } = this.props;
        if (itemType && itemId && Object.keys(availableApi).indexOf(itemType) !== -1) {
            getApiItemData({ itemType, itemId, language, season })
                .then(result => this.setState({
                    data: result,
                    isLoading: false,
                }))
                .catch(error => this.setState({
                    data: null,
                    error,
                    isLoading: false,
                }));
        } else {
            this.setState({
                data: null,
                isLoading: false,
            });
        }

        if (Object.keys(availableApi).indexOf(itemType) === -1) {
            // eslint-disable-next-line no-console
            console.warn(`Item type '${itemType}' is not supported.`);
        }
    }

    render() {
        const { children: Component, season = defaultSeason, language = defaultLanguage } = this.props;
        let updatedProps = {};
        if (Component.type.mapApiItemDataToProps
            && typeof Component.type.mapApiItemDataToProps === 'function') {
            updatedProps = this.state.data ?
                Component.type.mapApiItemDataToProps({ ...this.state.data, ...{ language } }) :
                {};
        } else {
            console.error(`Requested Component do not have corresponding method for data mapping. You need to create it directly in the Component.`); /* eslint-disable-line */
        }

        // eslint-disable-next-line
        return <Component.type {...Component.props} {...updatedProps} language={language} season={season} />;
    }
}

ItemDataFetcher.defaultProps = {
    language: defaultLanguage,
    season: defaultSeason,
};

ItemDataFetcher.propTypes = {
    children: PropTypes.node.isRequired,
    itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    itemType: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    season: PropTypes.string.isRequired,
};

export default ItemDataFetcher;
