import { inIframe } from './browserIframeUtils';

/**
 *
 * @param url
 * @returns {string}
 */
const createGoogleTranslateProxyUrl = (url) => {
    try {
        /* eslint-disable-next-line max-len */
        // https://feature--fix--gtranslator--82410-vps416-xiag-dev.translate.goog/en/museums/?city=stmoritz&_x_tr_sl=auto&_x_tr_tl=ru&_x_tr_hl=ru&_x_tr_pto=ajax,elem
        const { location } = (typeof window !== 'undefined' && window) ? window : {};
        if (location) {
            const {
                hostname, // "feature--fix--gtranslator--82410-vps416-xiag-dev.translate.goog"
                /* eslint-disable-next-line max-len */
                // href, // "https://feature--fix--gtranslator--82410-vps416-xiag-dev.translate.goog/en/museums/?city=stmoritz&_x_tr_sl=auto&_x_tr_tl=ru&_x_tr_hl=ru&_x_tr_pto=ajax,elem"
                origin: gtProxyOrigin, // "https://feature--fix--gtranslator--82410-vps416-xiag-dev.translate.goog"
                // pathname, // "/en/museums/"
                // protocol, // "https:"
                search, // "?city=stmoritz&_x_tr_sl=auto&_x_tr_tl=ru&_x_tr_hl=ru&_x_tr_pto=ajax,elem"
            } = location;
            const GT_PROXY_HOST_SIGN = '.translate.goog';
            if (hostname.indexOf(GT_PROXY_HOST_SIGN) !== -1) {
                // Google Translate Proxy detected
                const GT_SEARCH_PARAMS_PREFIX_SIGN = '_x_tr_';
                const gtSearchParams = new URLSearchParams(search);
                gtSearchParams.forEach((value, key) => {
                    if (key.indexOf(GT_SEARCH_PARAMS_PREFIX_SIGN) !== 0) {
                        gtSearchParams.delete(key);
                    }
                });

                // calculate original request parts
                const urlObject = new URL(url, 'https://foo.bar');
                const { pathname: urlPathname, search: urlSearch } = urlObject;
                const urlSearchParams = new URLSearchParams(urlSearch);

                return `${gtProxyOrigin}${urlPathname}?${urlSearchParams.toString()}&${gtSearchParams.toString()}`;
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('[APP]', error);
    }
    return url;
};

/**
 *
 * @param url
 * @returns {string}
 */
const urlWrapper = (url) => {
    if (typeof window === 'undefined') {
        return url;
    }

    // /en/museums/berry-museum/?backFilter=%26city%3Dstmoritz%26openToday%3Dfalse
    if (inIframe()) {
        return createGoogleTranslateProxyUrl(url);
    }
    return url;
};

export {
    createGoogleTranslateProxyUrl,
    urlWrapper,
};
