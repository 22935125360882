import React from 'react';
import PropTypes from 'prop-types';
import { mappedSrcSet } from '@estm/react-utils';

import ImageLazyLoad from '../ImageLazyLoad';

import env from '../../env';
import { generateHashCode } from '../../utils/stringUtils';

import './styles.scss';

class Image extends React.Component {
    static applyWindowObjectFit() {
        if (window.objectFitImages) {
            window.objectFitImages();
        }
    }

    constructor(props) {
        super(props);
        this.generateImageHashClass = this.generateImageHashClass.bind(this);
    }

    componentDidMount() {
        Image.applyWindowObjectFit();
    }

    componentDidUpdate() {
        Image.applyWindowObjectFit();
    }

    generateImageHashClass() {
        const { imgSrc, className } = this.props;
        const imageHash = generateHashCode(`${imgSrc}:${className}`);
        return `img-hash-${imageHash}`;
    }

    render() {
        const {
            className,
            fluidContainerModifiers,
            forceLoading,
            imgAlt,
            imgSizes,
            imgSrc,
            imgSrcSet,
            imgTitle,
            modifiers,
            position,
        } = this.props;

        const imageHashClassName = this.generateImageHashClass();
        const commonAttr = {
            key: `image-key::${imageHashClassName}`,
            style: null,
            alt: imgAlt,
            title: imgTitle,
            className: `fluid-image__img ${imageHashClassName}`,
            'data-ofi-src': null,
        };
        const imageAttr = {
            loading: 'lazy',
            'data-srcSet': mappedSrcSet(imgSrcSet, env.OA_IMAGES_BASE),
            'data-src': imgSrc,
            'data-sizes': imgSizes,
        };
        const lazyAttr = {
            srcSet: mappedSrcSet(imgSrcSet, env.OA_IMAGES_BASE),
            src: imgSrc,
            sizes: imgSizes,
        };

        const disableLazyLoading = (typeof window === 'undefined' || !('IntersectionObserver' in window));

        const classNames = [className].concat(modifiers.map(name => `${className}--${name}`)).join(' ');

        const fluidContainerClassName = 'fluid-image';
        const fluidContainerClassNames = [fluidContainerClassName]
            .concat([position])
            .concat(fluidContainerModifiers.map(name => `${fluidContainerClassName}--${name}`))
            .filter(Boolean)
            .join(' ');

        return (
            <div className={classNames} title={imgTitle}>
                <div className={fluidContainerClassNames}>
                    {
                        !forceLoading && !disableLazyLoading && <ImageLazyLoad {...lazyAttr} {...commonAttr} />
                    }
                    {
                        !forceLoading && disableLazyLoading && <img {...imageAttr} {...commonAttr} alt={imgAlt} />
                    }
                    {
                        forceLoading && <img {...lazyAttr} {...commonAttr} alt={imgAlt} />
                    }
                </div>
            </div>
        );
    }
}

Image.propTypes = {
    className: PropTypes.string,
    fluidContainerModifiers: PropTypes.arrayOf(PropTypes.string),
    forceLoading: PropTypes.bool,
    imgAlt: PropTypes.string,
    imgSizes: PropTypes.string,
    imgSrc: PropTypes.string,
    imgSrcSet: PropTypes.arrayOf(PropTypes.string),
    imgTitle: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    position: PropTypes.string,
};

Image.defaultProps = {
    className: '',
    fluidContainerModifiers: [],
    forceLoading: false,
    imgAlt: 'Default alt text',
    imgSizes: '',
    imgSrc: '',
    imgSrcSet: [],
    imgTitle: 'Default title tag',
    modifiers: [], // modifier css must be defined in the component which provide original class name
    position: '',
};

export default Image;
