import env from '../../env';

export default {
    activity: {
        name: 'Activity',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/activity/%s`,
    },
    'engadin-avalanches': {
        name: 'Engadin Avalanches Map and Snow Report',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/engadin-avalanches`,
    },
    'engadin-resorts': {
        name: 'Engadin Resorts List With Slopes included',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/engadin-resorts`,
    },
    'engadin-ski-tracks': {
        // https://www.engadin.stmoritz.ch/winter/de/api/slopes/cross-country-ski-run/
        // https://www.engadin.stmoritz.ch/winter/de/api/slopes/cross-country-ski-run/seen/
        // https://staging-objects-api.vps289.xiag.dev/v1/engadin-ski-tracks
        name: 'Engadin Cross Country Ski Tracks',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/engadin-ski-tracks`,
    },
    'green-solutions-ski-tracks': {
        // https://staging-objects-api.vps289.xiag.dev/v1/green-solutions-ski-tracks
        name: 'Green Solutions Ski Tracks',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/green-solutions-ski-tracks`,
    },
    'engadin-sledging': {
        // https://www.engadin.stmoritz.ch/winter/de/api/slopes/sledging/
        // https://staging-objects-api.vps289.xiag.dev/v1/engadin-sledges
        name: 'Engadin Sledging',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/engadin-sledges`,
    },
    'green-solutions-sledging': {
        // https://staging-objects-api.vps289.xiag.dev/v1/green-solutions-sledges
        name: 'Green Solutions Sledging',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/green-solutions-sledges`,
    },
    'engadin-winter-hikes': {
        // https://www.engadin.stmoritz.ch/winter/de/api/slopes/winter-hiking/seen/
        // https://staging-objects-api.vps289.xiag.dev/v1/engadin-winter-hikes
        name: 'Engadin Winter Hikes',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/engadin-winter-hikes?type=%s`,
    },
    'gs-hiking-trails': {
        // https://staging-objects-api.vps289.xiag.dev/v1/green-solutions-hiking-trails
        name: 'Green Solutions Hiking Trails',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/green-solutions-hiking-trails?type=%s`,
    },
    events: {
        name: 'Events',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/events-spot/%s`,
    },
    hotel: {
        name: 'Hotel',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/hotel/%s`,
    },
    'infosnow-informer': {
        name: 'Infosnow',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/infosnow-informer`,
    },
    'webcams-locations': {
        name: 'Webcams By Location',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/webcams?feature=location&per-page=50&sort=panorama&sortDir=asc`,
    },
    'webcams-mountains': {
        name: 'Webcams By Mountains',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/webcams?feature=mountain&per-page=50&sort=panorama&sortDir=asc`,
    },
    ticker: {
        name: 'Ticker',
        apiItemUrlPattern: `${env.OBJECTS_API_URL}v1/tickers`,
    },
};
