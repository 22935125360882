import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import './styles.scss';

/**
 *
 * @visibleName Ticker
 */
class Ticker extends React.Component {
    static mapApiItemDataToProps(data) {
        if (data && data.tickers && data.tickers.length > 0) {
            const { name, ticker, shortDescriptionHtml } = data.tickers[0];

            return {
                title: name,
                date: ticker.title,
                text: shortDescriptionHtml,
            };
        }

        return {
            title: '',
            date: '',
            text: '',
        };
    }

    render() {
        const { title, date, text, linkUrl, linkText, modificators } = this.props;
        const className = 'ticker';
        const classNames = [className].concat(modificators.map(name => `${className}--${name}`));

        /* eslint-disable react/no-danger */
        return title && (title !== '') && text && (text !== '') ? (
            <div className={classNames.join(' ')}>
                <div className={`${className}__title`}>{title}</div>
                <div className={`${className}__dates`}>{date}</div>
                <div className={`${className}__links`} dangerouslySetInnerHTML={{ __html: text }} />
                <div className={`${className}__more`}>
                    <a href={linkUrl}><Icon icon="estm_eng_chevron_thick" />{linkText}</a>
                </div>
            </div>
        ) : <div />;
    }
}

Ticker.formFields = Object.assign({}, {
    title: <input />,
    date: <input />,
    text: <textarea />,
    linkUrl: <input />,
    linkText: <input />,
});

Ticker.propTypes = {
    title: PropTypes.string,
    date: PropTypes.string,
    text: PropTypes.string,
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    modificators: PropTypes.arrayOf(PropTypes.string),
};

Ticker.defaultProps = {
    title: '',
    date: '',
    text: '',
    linkUrl: '',
    linkText: '',
    modificators: [],
};

export default Ticker;
