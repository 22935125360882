import * as React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon from '../Icon';

export class Slider extends SlickSlider {
    render() {
        const PrevArrow = <div><Icon icon="engadin_slider_arrow" /></div>;
        const NextArrow = <div><Icon icon="engadin_slider_arrow" /></div>;

        return (
            <SlickSlider
                dots
                {...this.props}
                prevArrow={PrevArrow}
                nextArrow={NextArrow}
            />
        );
    }
}

export default Slider;
