import React from 'react';
import PropTypes from 'prop-types';

import { availableLanguages, defaultLanguage } from '../../constants/languages';
import { urlWrapper } from '../../utils/urlWrapperUtils';
import Image from '../Image';
import SliderHeroImage from '../Slider';
import Ticker from '../Ticker';
import TargetSelect from '../Utils/TargetSelect';
import ItemDataFetcher from '../../api/object-admin/ItemDataFetcher';

import './styles.scss';

/**
 *
 * @visibleName Start Page Hero Image
 */
class HeroImage extends React.Component {
    constructor(props) {
        super(props);
        this.createSlides = this.createSlides.bind(this);
    }

    createSlides() {
        const className = 'hero-image';
        const list = [];

        for (let i = 1; i <= this.props.slideAmount; i += 1) {
            if (this.props[`imgSrc${i}`] !== '') {
                const imgSrc = this.props[`imgSrc${i}`];
                const imgAlt = this.props[`imgAlt${i}`];
                const imgTitle = this.props[`imgTitle${i}`];
                const imgUrl = this.props[`imgUrl${i}`];
                const target = this.props[`target${i}`];
                const imageElement = (
                    <Image
                        key={`slide::${i}`}
                        className={`${className}__img`}
                        imgSrc={imgSrc}
                        imgAlt={imgAlt}
                        imgTitle={imgTitle}
                        imgSrcSet={[
                            `${imgSrc} 320w`,
                            `${imgSrc} 500w`,
                            `${imgSrc} 640w`,
                            `${imgSrc} 768w`,
                            `${imgSrc} 900w`,
                            `${imgSrc} 1024w`,
                            `${imgSrc} 1366w`,
                            `${imgSrc} 1600w`,
                            `${imgSrc} 1920w`,
                            `${imgSrc} 2048w`,
                            `${imgSrc} 3072w`,
                        ]}
                        imgSizes={
                            '(min-width: 1536px) 1920px,' +
                            '(min-width: 1140px) 100vw,' +
                            '(min-width: 900px) 100vw,' +
                            '(min-width: 600px) 100vw, 100vw'
                        }
                    />
                );
                list.push(
                    imgUrl ? (
                        <a
                            className="hero-image__link"
                            href={urlWrapper(imgUrl)}
                            rel="noopener noreferrer"
                            target={target}
                        >
                            {imageElement}
                        </a>
                    ) : imageElement,
                );
            }
        }
        return list;
    }

    render() {
        const { language, caption, preambleTitle, preambleText, tickerUrlLink, tickerUrlText } = this.props;

        const className = 'hero-image';

        return (
            <div className={className}>
                <div className={`${className}__container`}>
                    <div className={`${className}__caption`}>
                        <span>Engadin.</span> {caption}
                    </div>
                    <SliderHeroImage fade autoplay autoplaySpeed={10000}>
                        {this.createSlides()}
                    </SliderHeroImage>
                    <ItemDataFetcher itemType="ticker" itemId={1} language={language}>
                        <Ticker linkUrl={tickerUrlLink} linkText={tickerUrlText} />
                    </ItemDataFetcher>
                </div>
                {preambleTitle && preambleText ?
                    <div className={`${className}__text-block`}>
                        <div>{preambleTitle}</div>
                        <p>
                            {preambleText}
                        </p>
                    </div>
                    : ''}
            </div>
        );
    }
}


HeroImage.formFields = Object.assign({}, {
    imgSrc1: <input />,
    imgAlt1: <input />,
    imgTitle1: <input />,
    imgUrl1: <input />,
    target1: <TargetSelect />,

    imgSrc2: <input />,
    imgAlt2: <input />,
    imgTitle2: <input />,
    imgUrl2: <input />,
    target2: <TargetSelect />,

    imgSrc3: <input />,
    imgAlt3: <input />,
    imgTitle3: <input />,
    imgUrl3: <input />,
    target3: <TargetSelect />,

    imgSrc4: <input />,
    imgAlt4: <input />,
    imgTitle4: <input />,
    imgUrl4: <input />,
    target4: <TargetSelect />,

    imgSrc5: <input />,
    imgAlt5: <input />,
    imgTitle5: <input />,
    imgUrl5: <input />,
    target5: <TargetSelect />,

    caption: <input />,
    preambleTitle: <input />,
    preambleText: <input />,

    tickerUrlLink: <input />,
    tickerUrlText: <input />,
});

HeroImage.propTypes = {
    slideAmount: PropTypes.number,
    language: PropTypes.oneOf(availableLanguages),

    /* eslint-disable */
    // getting of the properties made by key
    imgSrc1: PropTypes.string,
    imgAlt1: PropTypes.string,
    imgTitle1: PropTypes.string,
    imgUrl1: PropTypes.string,
    target1: PropTypes.string,

    imgSrc2: PropTypes.string,
    imgAlt2: PropTypes.string,
    imgTitle2: PropTypes.string,
    imgUrl2: PropTypes.string,
    target2: PropTypes.string,

    imgSrc3: PropTypes.string,
    imgAlt3: PropTypes.string,
    imgTitle3: PropTypes.string,
    imgUrl3: PropTypes.string,
    target3: PropTypes.string,

    imgSrc4: PropTypes.string,
    imgAlt4: PropTypes.string,
    imgTitle4: PropTypes.string,
    imgUrl4: PropTypes.string,
    target4: PropTypes.string,

    imgSrc5: PropTypes.string,
    imgAlt5: PropTypes.string,
    imgTitle5: PropTypes.string,
    imgUrl5: PropTypes.string,
    target5: PropTypes.string,
    /* eslint-enable */

    caption: PropTypes.string,
    preambleTitle: PropTypes.string,
    preambleText: PropTypes.string,

    tickerUrlLink: PropTypes.string,
    tickerUrlText: PropTypes.string,
};

HeroImage.defaultProps = {
    slideAmount: 5,
    language: defaultLanguage,

    imgSrc1: '/images/img5.jpg',
    imgAlt1: 'Default alt text',
    imgTitle1: 'Default title text',
    imgUrl1: '',
    target1: '_self',

    imgSrc2: '/images/img4.jpg',
    imgAlt2: 'Default alt text',
    imgTitle2: 'Default title text',
    imgUrl2: '',
    target2: '_self',

    imgSrc3: '',
    imgAlt3: '',
    imgTitle3: '',
    imgUrl3: '',
    target3: '_self',

    imgSrc4: '',
    imgAlt4: '',
    imgTitle4: '',
    imgUrl4: '',
    target4: '_self',

    imgSrc5: '',
    imgAlt5: '',
    imgTitle5: '',
    imgUrl5: '',
    target5: '_self',

    caption: 'Diese Berge, diese Seen, dieses Licht.',
    preambleTitle: 'Entdecken Sie eine neue Perspektive',
    preambleText: 'Vieles im Leben ist eine Frage der\n' +
        '                    richtigen Perspektive. Im Engadin finden Sie davon reichlich: archaische Bergnatur,\n' +
        '                    heilende Quellen und beinahe unendliche sportliche Möglichkeiten.',

    tickerUrlLink: '/de/news-ticker/',
    tickerUrlText: 'mehr informationen',
};

export default HeroImage;
