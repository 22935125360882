function sameValueLanguageMapping(value, languages = ['de', 'en', 'it']) {
    return languages.map(language => ({ language, value }));
}

function getLanguageMapping(multilangObject) {
    const result = [];
    Object.keys(multilangObject).forEach((key) => {
        result.push({ language: key, value: multilangObject[key] });
    });
    return result;
}

/**
 * Returns hash of the string
 * @param str string
 * @returns {number}
 */
function generateHashCode(str) {
    let hash = 0;
    if (str.length === 0) return hash;

    let chr;
    /* eslint-disable no-bitwise, no-plusplus */
    for (let i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    /* eslint-enable no-bitwise, no-plusplus */
    return hash;
}

export {
    generateHashCode,
    getLanguageMapping,
    sameValueLanguageMapping,
};
