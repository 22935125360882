switch (process.env.REACT_APP_NODE_ENV) {
    case 'production':
        module.exports = require('./env.prod'); /* eslint-disable-line global-require */
        break;
    case 'staging':
        module.exports = require('./env.stage'); /* eslint-disable-line global-require */
        break;
    default:
        module.exports = require('./env.dev'); /* eslint-disable-line global-require */
        break;
}
