/**
 * Returns IFRAME detection
 * @returns {boolean}
 */
const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export {
    inIframe,
};
