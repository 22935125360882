const OA_IMAGES_BASE = 'staging-images.estm.xiag.ch';
const SITE_DOMAIN_NAME = 'www.engadin.vps419.xiag.dev';
// const SITE_DOMAIN_NAME = 'www.estm-engadin-s4.local'; // local site
const ENGADIN_STMORITZ_DOMAIN_NAME = 'www.engadin.stmoritz.ch';
const VPS_DOMAIN_NAME = process.env.REACT_APP_DEMO_DOMAIN || SITE_DOMAIN_NAME;

module.exports = {
    VPS_DOMAIN_URL: 'http://localhost/',
    SITE_FULL_URL: `https://${SITE_DOMAIN_NAME}/`,
    ENGADIN_STMORITZ_FULL_URL: `https://${ENGADIN_STMORITZ_DOMAIN_NAME}/`,
    OBJECTS_API_URL: 'https://staging-objects-api.vps289.xiag.dev/',
    OA_IMAGES_BASE,
    OA_IMAGES_SHORT_TTL_PATH: '/object/refresh/',
    OA_IMAGES_URL: `https://${OA_IMAGES_BASE}/`,
    IMG_NOT_FOUND_URL: `https://${OA_IMAGES_BASE}/object/6501164/original.jpg`,
    TOKENS: {
        googleMap: 'AIzaSyCfv4bmCR6GghQ5mvn1C28aKhHGmtPFxWQ',
    },
    // Share links
    DEFAULT_LINK_TO_SHARE: `https://${SITE_DOMAIN_NAME}/`,
    SHARE_BY: {
        facebook: 'https://www.facebook.com/sharer.php?u=%s',
        twitter: 'http://www.twitter.com/share?url=%s',
        mailto: 'mailto:?body=Link: %1$s&subject=%2$s',
    },

    // LEGACY PARTS
    LONGINES_LAYER_URL: `https://${VPS_DOMAIN_NAME}/en/layers/skiworldcup-lon-livewatch`,

    // MapTiler
    /* eslint-disable max-len */
    MAPTILER_OUTDOOR_OSM_URL: 'https://api.maptiler.com/maps/outdoor/256/{z}/{x}/{y}.png?key=BB33oAznVkDM4asG9CKz&env=dev',
    MAPTILER_STREETS_OSM_URL: 'https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=BB33oAznVkDM4asG9CKz&env=dev',
    MAPTILER_OSM_ATTRIBUTION: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
};
