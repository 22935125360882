// eslint-disable-next-line import/prefer-default-export
export const ESTM_SEASON_WINTER = 'winter';
export const ESTM_SEASON_SUMMER = 'summer';
export const defaultSeason = ESTM_SEASON_WINTER;
export const availableSeasons = [
    ESTM_SEASON_SUMMER,
    ESTM_SEASON_WINTER,
];

export const seasonInfo = {
    [ESTM_SEASON_SUMMER]: {
        title: 'Summer',
    },
    [ESTM_SEASON_WINTER]: {
        title: 'Winter',
    },
};
