import React from 'react';
import PropTypes from 'prop-types';

class ImageLazyLoad extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isInView: false };
        this.image = React.createRef();
    }

    componentDidMount() {
        if (!('IntersectionObserver' in window) && this.image.current !== null) {
            this.loadImage();
            return;
        }

        const ioConfig = {
            root: null,
            rootMargin: '0px',
        };

        const io = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        this.loadImage();
                        io.unobserve(entry.target);
                    }
                });
            },
            ioConfig,
        );

        io.observe(this.image.current);
    }

    loadImage() {
        this.setState({ isInView: true });
    }

    render() {
        const { src, srcSet, sizes, alt, title, className, ...restProps } = this.props;

        return (
            <img
                srcSet={this.state.isInView ? srcSet : []}
                src={this.state.isInView ? src : ''}
                sizes={sizes}
                alt={alt}
                title={title}
                className={className}
                style={{
                    visibility: this.state.isInView ? 'visible' : 'hidden',
                    ...restProps.style,
                }}
                {...restProps}
                ref={this.image}
            />
        );
    }
}

ImageLazyLoad.propTypes = {
    src: PropTypes.string,
    srcSet: PropTypes.arrayOf(PropTypes.string),
    sizes: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
};

ImageLazyLoad.defaultProps = {
    alt: '',
    src: '',
    srcSet: [],
    sizes: '',
    title: '',
    className: '',
};

export default ImageLazyLoad;
